@import "theme";

.PageHeader {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(0,0,0, .3) 0%, transparent 100%);
  }
}

.dark {
  .PageHeader {
    color: $color-dk;
  }
}

.light {
  .PageHeader {
    color: $color-lt;
  }
}