@import "theme";

.OrdersList {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  transition: background 300ms;

  &__child {
    padding: 5px 10px;
    border-bottom: 3px solid;
    position: relative;

    .label {
      font-size: 16px;
    }

    .value {
      font-size: 16px;
    }

    &_controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 120px;
        border: 1px solid;
        margin: 7px 0;
        border-radius: 3px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;

        &:not(:last-child) {
          margin-right: 5px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &_sort {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 60px;
      display: flex;
      justify-content: space-between;

      .sort-button {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background 300ms;

        &:hover {
          cursor: pointer;
        }
      }

      .sort-button.disabled:hover {
        cursor: default;
      }
    }

    &_delivered-status {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 10px 5px;
      border-radius: 3px;
      border: 1px solid;
    }
  }
}

.dark {
  .OrdersList {
    background: $background-sub-dk;
    color: $color-dk;

    &__child {
      border-color: $background-dk;

      .label {
        color: $color-sub-dk;
      }

      .value {
        color: $color-dk;
      }

      &_controls {
        .button {
          border-color: $background-dk;
          color: $color-dk;
        }

        .delivered-button {
          background: $color-green-dk;
        }

        .call-button {
          background: $color-call-dk;
        }

        .map-button {
          background: $color-map-dk;
        }
      }

      &_sort {
        .sort-button {
          color: $color-green-dk;
          background: $background-dk;

          &:hover {
            background: $color-hover-dk;
          }
        }

        .sort-button.disabled {
          color: $color-sub-dk;
          background: $background-sub-dk;
        }
      }

      &_delivered-status {
        background: $color-green-dk;
        border-color: $background-dk;
      }

      &_delivered-status.late {
        background: $color-warning-dk;
      }
    }
  }
}

.light {
  .OrdersList {
    background: $background-sub-lt;
    color: $color-lt;

    &__child {
      border-color: $color-lt;

      .label {
        color: $color-sub-lt;
      }

      .value {
        color: $color-lt;
      }

      &_controls {
        .button {
          border-color: $background-lt;
          color: $color-lt;
        }

        .delivered-button {
          background: $color-green-lt;
        }

        .call-button {
          background: $color-call-lt;
        }

        .map-button {
          background: $color-map-lt;
        }
      }

      &_sort {
        .sort-button {
          color: $color-green-lt;
          background: rgba(0,0,0, .1);

          &:hover {
            background: $color-hover-lt;
          }
        }

        .sort-button.disabled {
          color: $color-sub-lt;
          background: $background-sub-lt;
        }
      }

      &_delivered-status {
        background: $color-green-lt;
        border-color: $background-lt;
      }

      &_delivered-status.late {
        background: $color-warning-lt;
      }
    }
  }
}