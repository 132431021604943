@import "theme";

.TabBar {
  position: fixed;
  top: calc(100% - 60px);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px 0;

  &__tab {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transition: color 300ms;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      border-right: 1px solid;
    }

    &_icon {
      font-size: 20px;
      margin-bottom: -3px;
    }

    &_count {
      position: absolute;
      left: calc(50% + 14px);
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      font-size: 10px;
      border: 1px solid;
    }
  }
}

.TabBar:after {
  content: '';
  position: absolute;
  top: -20px;
  width: 100%;
  height: 20px;
  background: linear-gradient(0deg, rgba(0,0,0, .3) 0%, transparent 100%);
}

.dark {
  .TabBar {
    background: $background-dk;

    &__tab {
      color: $color-sub-dk;

      &:hover {
        color: $color-dk;
      }

      &:not(:last-child) {
        border-color: $tab-border-color-dk;
      }

      &.active {
        color: $color-green-dk;
      }

      &_count {
        background: $color-green-dk;
        color: $color-dk;
        border-color: $color-dk;
      }
    }
  }
}

.light {
  .TabBar {
    background: $background-lt;

    &__tab {
      color: $color-sub-lt;

      &:hover {
        color: $color-lt;
      }

      &:not(:last-child) {
        border-color: $tab-border-color-lt;
      }

      &.active {
        color: $color-green-lt;
      }

      &_count {
        background: $color-green-lt;
        color: $color-lt;
        border-color: $color-lt;
      }
    }
  }
}