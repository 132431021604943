.RouteMap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &__content {
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
    div:last-child {
      width: 100% !important;
      height: calc(100% - 40px) !important;
    }
  }

  .ymaps-2-1-77-map-copyrights-promo, .ymaps-2-1-77-copyrights-pane {
    display: none;
  }
}

.ymaps-2-1-77-fullscreen {
  .ymaps-2-1-77-map-copyrights-promo, .ymaps-2-1-77-copyrights-pane {
    display: none;
  }
}