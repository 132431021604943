@import "theme";

.Menu {
  width: 100%;
  height: 100%;

  &__content {
    width: 100%;
  }

  &__items {
    padding: 20px 0;
  }

  &__item {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    transition: background 300ms, color 300ms;
    margin-bottom: 10px;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .ant-switch.ant-switch-checked {
    background: #00CE7D;
  }
}

.dark {
  .Menu {
    &__item {
      background: $background-sub-dk;
      color: $color-dk;

      &:hover {
        background: $color-hover-dk;
      }
    }

    .ant-switch.ant-switch-checked {
      background: $color-green-dk;
    }
  }
}

.light {
  .Menu {
    &__item {
      background: $background-sub-lt;
      color: $color-lt;
      border: solid $color-lt;
      border-width: 0 0 1px 0;

      &:hover {
        background: $color-hover-lt;
      }
    }

    .ant-switch.ant-switch-checked {
      background: $color-green-lt;
    }
  }
}