@import "tab-bar";
@import "login";
@import "page-header";
@import "orders-list";
@import "delivery";
@import "route-map";
@import "menu";
@import "preloader";
@import "personal";
@import "theme";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Courier-helper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
}

.Courier-helper__content {
  width: 100%;
  height: 100%;
}

.PageNotFount {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;

  svg {
    font-size: 80px;
    color: red;
    margin-bottom: 20px;
  }
}

.dark {
  .Courier-helper__content {
    background: $background-dk;
  }

  .PageNotFount {
    color: $color-dk;
  }
}

.light {
  .Courier-helper__content {
    background: $background-lt;
  }

  .PageNotFount {
    color: $color-lt;
  }
}