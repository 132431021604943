@import "theme";

.Personal {
  &__content {
    display: flex;
    flex-flow: column nowrap;
  }

  &__icon {
    font-size: 80px;
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  &__data_block {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid;
    position: relative;
    padding: 0 20px;
    font-size: 16px;
  }
}

.dark {
  .Personal {
    &__icon {
      color: $color-green-dk;
    }

    &__data_block {
      border-color: $background-dk;
      background: $background-sub-dk;
    }

    &__data_label {
      color: $color-sub-dk;
    }

    &__data_value {
      color: $color-dk;
    }
  }
}

.light {
  .Personal {
    &__icon {
      color: $color-green-lt;
    }

    &__data_block {
      background: $background-sub-lt;
      border: solid $color-lt;
      border-width: 0 0 1px 0;
    }

    &__data_label {
      color: $color-sub-lt;
    }

    &__data_value {
      color: $color-lt;
    }
  }
}