.Preloader {
  margin: 0 auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);

  .bar {
    background-color: rgba(0, 206, 125, .1);
    height: 100%;
    width: 8px;
    display: inline-block;
    float: left;
    margin-left: 2px;
    -webkit-animation: delay 0.8s infinite ease-in-out;
    animation: delay 0.8s infinite ease-in-out;
  }
  .bar2{
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
    background-color: rgba(0, 206, 125, .2)
  }
  .bar3{
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
    background-color: rgba(0, 206, 125, .4)
  }
  .bar4{
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    background-color: rgba(0, 206, 125, .6)
  }
  .bar5{
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
    background-color: rgba(0, 206, 125, .8)
  }
  .bar6{
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
    background-color: rgba(0, 206, 125, 1)
  }
}


@-webkit-keyframes delay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.05) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes delay {
  0%, 40%, 100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}