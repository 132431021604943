// Light theme colors
$background-lt: #ffffff;
$background-sub-lt: #ffffff;
$color-lt: #000000;
$color-sub-lt: rgba(0, 0, 0, .6);
$color-green-lt: #00CE7D;
$color-hover-lt: rgba(0, 0, 0, .05);
$color-call-lt: #b0b600;
$color-map-lt: #2d75ff;
$color-warning-lt: #cd1724;
$tab-border-color-lt: rgba(0, 0, 0, .4);

// Dark theme colors
$background-dk: #1b2b35;
$background-sub-dk: #213542;
$color-dk: #ffffff;
$color-sub-dk: rgba(255, 255, 255, .4);
$color-green-dk: #00CE7D;
$color-hover-dk: #273e48;
$color-call-dk: #b0b600;
$color-map-dk: #2d75ff;
$color-warning-dk: #cd1724;
$tab-border-color-dk: #000000;