@import "theme";

.Delivery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &__content {
    padding: 0 0 20px 0;
    overflow: hidden;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__filters {
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    margin: 20px 10px 10px;
    font-weight: 600;
    position: relative;

    &_item {
      flex: calc(100% / 2 - 10px) 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      transition: color 300ms;
      border-radius: 3px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__refresh {
    position: absolute;
    top: 9px;
    right: 10px;
  }
}

.dark {
  .Delivery {
    &__filters {
      &_item {
        color: $color-sub-dk;

        &:hover {
          color: $color-dk;
        }

        &.active {
          color: $color-green-dk;
          background: $background-sub-dk;
        }
      }
    }
  }
}

.light {
  .Delivery {
    &__filters {
      &_item {
        color: $color-sub-lt;

        &:hover {
          color: $color-lt;
        }

        &.active {
          color: $color-green-lt;
          background: $background-sub-lt;
          border: 1px solid $color-sub-lt;
        }
      }
    }
  }
}