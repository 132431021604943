@import "theme";

.Login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #1b2b35;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  &__logo img {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
  }

  &__form form, .ant-form-item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .ant-form-item-label label {
    color: #fff;
  }

  .submit {
    margin-top: 20px;
  }

  .ant-form-item-explain {
    text-align: center;
  }
}

.dark {
  .Login {
    background: $background-dk;

    .ant-form-item-label label {
      color: $color-dk;
    }
  }
}

.light {
  .Login {
    background: $background-lt;

    .ant-form-item-label label {
      color: $color-lt;
    }
  }
}